import React, {useEffect, useState} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import useWindowsDimensionHook from "../../hooks/useWindowsDimensionHook";
import {aqiAxiosInstance, backendAxiosInstance} from "../../axios";

import moment from "moment";

export const MapView = ({aqiDetails, setAqiDetails, loading, setLoading}) => {

    const [worldCoords, setWorldCoords] = useState(null);

    const {isMobile} = useWindowsDimensionHook();

    const containerStyle = {
        width: isMobile ? '100%' : "50vw",
        height: isMobile ? '50vh' : '100%',
    };

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
    })

    const [map, setMap] = React.useState(null)
    const mapRef = React.useRef(null)

    const [AQIMarkerList, setAQIMarkerList] = useState([])

    const [centerPoint, setCenterPoint] = useState({
        lat: 37.773972,
        lng: -122.431297
    })

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds(centerPoint);
        // map.fitBounds(bounds);
        setMap(map)
    }, [centerPoint])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onGetDetails = (id, aqi, name, utime) => {
        if (aqi !== "placeholder") {
            setLoading(true)
            backendAxiosInstance.get(`/api/station/${id}/pastData`)
                .then((response) => {
                    setAqiDetails({
                        ...response.data,
                        aqi,
                        name,
                        datetime: moment(utime).format('DD/MM/YYYY HH:mm:ss')
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setCenterPoint({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            },
            function (error) {
                // alert("Please allow location access to use this feature")
            }
        );
    }, [])

    useEffect(() => {
        if (isLoaded) {
            setWorldCoords({
                north: 84.9,
                south: -84.9,
                east: 179.9,
                west: -179.9,
            })
        }
    }, [isLoaded])

    return isLoaded ? (
        <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            center={centerPoint}
            zoom={7}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                restriction: {
                    latLngBounds: worldCoords,
                    strictBounds: true,
                }
            }}
            onIdle={() => {
                if (map) {
                    const bounds = map.getBounds();

                    // getSouthWest -> bottom left
                    // getNorthEast -> top right

                    // console.log("bottom left", bounds.getSouthWest().lat(), bounds.getSouthWest().lng())
                    // console.log("top right", bounds.getNorthEast().lat(), bounds.getNorthEast().lng())

                    aqiAxiosInstance.post('/mapq2/bounds', {
                        bounds: `${bounds.getSouthWest().lng()},${bounds.getSouthWest().lat()},${bounds.getNorthEast().lng()},${bounds.getNorthEast().lat()}`,
                        country: "",
                        inc: "placeholders",
                        viewer: "webgl",
                        zoom: 7
                    })
                        .then((res) => {
                            setAQIMarkerList(res.data.data)
                        })

                }
            }}
        >
            {
                AQIMarkerList.map((details, index) => {
                    if (details.aqi !== "-" && details.aqi !== "placeholder") {
                        return (
                            <Marker
                                position={{lat: details.geo[0], lng: details.geo[1]}}
                                title={details.aqi}
                                // label={{
                                // 	text: details.aqi,
                                // 	color: "black",
                                // }}
                                onClick={() => {
                                    onGetDetails(details.idx, details.aqi, details.name, details.utime)
                                }}

                                icon={{
                                    url: require(`../../images/${details.aqi}.png`),
                                    scaledSize: {width: 40, height: 40, widthUnit: 'px', heightUnit: 'px'}
                                }}

                                // onLoad={async (marker) => {
                                // 	const customIcon = (opts) => Object.assign({
                                // 		// path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                // 		path: generateCircleSvgPath(0, 0, 15),
                                // 		fillOpacity: 1,
                                // 		strokeWeight: 0,
                                // 		scale: 1,
                                // 	}, opts);
                                //
                                // 	let color = await generateMarkerIconColor(details.aqi);
                                //
                                // 	marker.setIcon(customIcon({
                                // 		fillColor: color,
                                // 	}));
                                //
                                // 	return marker
                                // }}
                            />
                        )
                    }
                })
            }
        </GoogleMap>
    ) : <></>
}
