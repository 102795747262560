export const LabelFullName = {
    "aqi": "AQI",
    "pm25": "PM 2.5",
    "10": "PM 10",
    "o3": "Ozone",
    "no2": "Nitrogen Dioxide",
    "so2": "Sulfur Dioxide",
    "co": "Carbon Monoxide",
    "pm10": "Asean PM 10",
    "t": "Temperature",
    "h": "Humidity",
    "dew": "Dew Point",
    "wg": "Wind Gust",
    "p": "Pressure",
    "w": "Wind Speed",
    "r": "Rainfall",
}

export const AQILevelColor = {
    "Good": "#009966",
    "Moderate": "#FFDE33",
    "Unhealthy for Sensitive Groups": "#FF9933",
    "Unhealthy": "#CC0133",
    "Very Unhealthy": "#660099",
    "Hazardous": "#7E0023",
}

export const AQILevel = [
    {
        "level": "Good",
        "min": 0,
        "max": 50,
    },
    {
        "level": "Moderate",
        "min": 51,
        "max": 100,
    },
    {
        "level": "Unhealthy for Sensitive Groups",
        "min": 101,
        "max": 150,
    },
    {
        "level": "Unhealthy",
        "min": 151,
        "max": 200,
    },
    {
        "level": "Very Unhealthy",
        "min": 201,
        "max": 300,
    },
    {
        "level": "Hazardous",
        "min": 301,
        "max": 999,
    }
]
