import { useState, useEffect } from 'react';

export default function useWindowsDimensionHook() {
    function getWindowDimensions() {

        const { innerWidth: width, innerHeight: height } = window;
        const isMobile = width <= 768
        return {
            width,
            height,
            isMobile
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    return windowDimensions;
}
