import {Card, Col, Row, Space, Spin, Statistic} from "antd";
import React, {useEffect} from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import faker from 'faker';
import moment from "moment";
import {AQILevel, AQILevelColor, LabelFullName} from "../../constants";
import useWindowsDimensionHook from "../../hooks/useWindowsDimensionHook";
import {aqiAxiosInstance, backendAxiosInstance} from "../../axios";

export const AQIDetails = ({aqiDetails, loading, setAqiDetails, onNumberClick}) => {

    const {isMobile} = useWindowsDimensionHook()

    const [level, setLevel] = React.useState(null);
    const [title, setTitle] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [mainDataSource, setMainDataSource] = React.useState([]);

    const onSetLevel = (aqi) => {
        aqi = +aqi

        AQILevel.map((item) => {
            if (aqi >= item.min && aqi <= item.max) {
                setLevel(item.level)
            }
        })
    }

    useEffect(() => {
        aqiAxiosInstance.get(`/mapq/nearest`)
            .then((response) => {
                const data = response.data.d[0];
                const id = data.x;
                const aqi = data.v;
                const name = data.u;
                const utime = data.t;
                backendAxiosInstance.get(`/api/station/${id}/pastData`)
                    .then((backendResponse) => {
                        setAqiDetails({
                            ...backendResponse.data,
                            aqi,
                            name,
                            datetime: moment(utime * 1000).format('DD/MM/YYYY HH:mm:ss')
                        })
                    })
            })
    }, [])

    useEffect(() => {

        let tempTitle = []

        let tempX = []
        let tempY = []

        if (aqiDetails) {

            console.log(aqiDetails)

            aqiDetails.data.map((item) => {
                tempTitle.push(LabelFullName[item.name])

                let tempx = []
                let tempy = []
                item.values.map((value) => {
                    tempx.push(moment(value.timestamp).format('DD/MM/YYYY HH:mm:ss'))
                    tempy.push(+value.value)
                })
                tempY.push(tempy)
                tempX.push(tempx)
            })
            setTitle(tempTitle)
            setLabels(tempX)
            setMainDataSource(tempY)

            onSetLevel(aqiDetails?.aqi)
        }
    }, [aqiDetails])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    return (
        <Space direction={"vertical"} style={{width: '100%', height: '100%'}}>
            {
                loading ? (
                    <Spin size={"large"}/>
                ) : (
                    <div style={isMobile ? { } : {overflowY: 'scroll', maxHeight: "95vh"}}>
                        <Card title={aqiDetails?.name} style={{width: "95%"}} onClick={onNumberClick?? null} hoverable={!!onNumberClick}>
                            <Row gutter={[16, 24]}>
                                <Col span={5} style={{backgroundColor: AQILevelColor[level], borderRadius: '10px'}}>
                                    <h1 style={{
                                        textAlign: 'center',
                                        fontSize: '200%',
                                        margin: "30% 10%"
                                    }}>{aqiDetails?.aqi ?? "-"}</h1>
                                </Col>
                                <Col span={19}>
                                    <Space style={{width: '100%', height: '100%'}} size={1} direction={"vertical"}>
                                        <h1 style={{fontSize: '2vw'}}>{level}</h1>
                                        <p>Latest Update: {aqiDetails?.datetime}</p>
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                        {/*<h1>Station: {aqiDetails?.name}</h1>*/}
                        {/*<h1>AQI: {aqiDetails?.aqi}</h1>*/}
                        {/*<h1>Level: <span style={{backgroundColor: AQILevelColor[level]}}>{level}</span></h1>*/}

                        {
                            title.map((item, index) => {
                                return (
                                    <>
                                        <Card title={item} style={{width: "95%", height: '100%'}}>
                                            <Bar
                                                style={{
                                                    maxHeight: '15vh'
                                                }}
                                                options={{
                                                    plugins: {
                                                        title: {
                                                            display: false,
                                                            text: item,
                                                        },
                                                        legend: {
                                                            display: false,
                                                        }
                                                    },
                                                    responsive: true,
                                                    interaction: {
                                                        mode: 'index',
                                                        intersect: false,
                                                    },
                                                    scales: {
                                                        x: {
                                                            stacked: true,
                                                            ticks: {
                                                                display: false
                                                            }
                                                        },
                                                        y: {
                                                            stacked: true,
                                                        },
                                                    },
                                                }}
                                                data={{
                                                    labels: labels[index],
                                                    datasets: [
                                                        {
                                                            label: item,
                                                            data: mainDataSource[index],
                                                            backgroundColor: 'rgb(255, 99, 132)',
                                                            stack: 'Stack 0',
                                                        }
                                                    ],
                                                }}/>
                                            <br/>
                                        </Card>
                                        <br/>
                                    </>
                                )
                            })
                        }

                    </div>
                )
            }
        </Space>
    )
}
