import axios from "axios";
import {showErrorNotification} from "../components/Notification";
import {IS_DEVELOPMENT_MODE} from "../helpers/environment";

export const aqiAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_AQI_URL,
})

aqiAxiosInstance.interceptors.request.use(function (config) {
	config.time = {startTime: new Date()};
	// const token = localStorage.getItem('accessToken');
	// if (token)
	// 	config.headers['Authorization'] = `Bearer ${textDecrypt(token)}`;
	return config;
});

aqiAxiosInstance.interceptors.response.use(function (res) {

	res.config.time.endTime = new Date();
	let duration = res.config.time.endTime - res.config.time.startTime;
	IS_DEVELOPMENT_MODE && console.log('duration', `${duration}ms`, 'response', res.data);
	return res;

}, function (error) {

	IS_DEVELOPMENT_MODE && console.log('error', (error.response &&
			error.response.data &&
			error.response.data.message) ||
		error.message ||
		error.toString());

	showErrorNotification(error);

	return Promise.reject(error);
});

export const backendAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
})

backendAxiosInstance.interceptors.request.use(function (config) {
	config.time = {startTime: new Date()};
	// const token = localStorage.getItem('accessToken');
	// if (token)
	// 	config.headers['Authorization'] = `Bearer ${textDecrypt(token)}`;
	return config;
});

backendAxiosInstance.interceptors.response.use(function (res) {

	res.config.time.endTime = new Date();
	let duration = res.config.time.endTime - res.config.time.startTime;
	IS_DEVELOPMENT_MODE && console.log('duration', `${duration}ms`, 'response', res.data);
	return res;

}, function (error) {

	IS_DEVELOPMENT_MODE && console.log('error', (error.response &&
			error.response.data &&
			error.response.data.message) ||
		error.message ||
		error.toString());

	showErrorNotification(error);

	return Promise.reject(error);
});
