import './App.css';
import {MainLayout} from "./layouts";

function App() {
  return (
    <>
      <MainLayout />
    </>
  );
}

export default App;
