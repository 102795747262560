import {Col, Row} from "antd";
import {MapView} from "../components/MapView";
import {AQIDetails} from "../components/AQIDetails";
import useWindowsDimensionHook from "../hooks/useWindowsDimensionHook";
import {useState} from "react";

export const MainLayout = () => {

	const { isMobile } = useWindowsDimensionHook();

	const [ aqiDetails, setAqiDetails ] = useState(null);
	const [loading, setLoading] = useState(false);

	return (
		<div style={{ minHeight: "100%" }}>
			{
				isMobile ? (
					<div style={{ overflowX: 'hidden' }}>
						<Row gutter={[16, 24]}>
							<MapView aqiDetails={aqiDetails} setAqiDetails={setAqiDetails} loading={loading} setLoading={setLoading} />
						</Row>
						<Row>
							<AQIDetails aqiDetails={aqiDetails} setAqiDetails={setAqiDetails} loading={loading} setLoading={setLoading} />
						</Row>
					</div>
				) : (
					<div style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
						<Row gutter={[16, 24]}>
							<Col span={12}>
								<MapView aqiDetails={aqiDetails} setAqiDetails={setAqiDetails} loading={loading} setLoading={setLoading} />
							</Col>
							<Col span={11} offset={1} style={{ height: '100vh' }}>
								<AQIDetails aqiDetails={aqiDetails} setAqiDetails={setAqiDetails} loading={loading} setLoading={setLoading} />
							</Col>
						</Row>
					</div>
				)
			}
		</div>
	)
}

export const DetailsLayout = () => {
	const { isMobile } = useWindowsDimensionHook();

	const [aqiDetails, setAqiDetails ] = useState(null);

	const openNewWindow = () => { isMobile? window.open('/', 'MayAir AQI', 'popup'):
		window.open('/', 'MayAir AQI', "popup,height=800,width=1920")};

	return <AQIDetails aqiDetails={aqiDetails} setAqiDetails={setAqiDetails} loading={null} onNumberClick={openNewWindow}/>
}
